import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

import React from 'react';
import { isArray } from 'tiny-compose-fns/noFp';
import { PageTemplate } from '../templates/page';

const findIndex = (fn, arr) => isArray(arr) ? arr.findIndex(fn) : undefined;

const splice = (start, deleteCount, item, arr) => {
  if (!isArray(arr)) return [];
  const copy = Array.from(arr);
  copy.splice(start, deleteCount, item);
  return copy;
};

const slicesMiddleware = (list = []) => {
  const contactInfoSlice = {
    __typename: 'PageBodyContactInfo',
    id: 'contact_info'
  };
  const ctaSliceIndex = findIndex(slice => slice.__typename === 'PrismicPageBodyCallToAction', list);
  if (ctaSliceIndex > -1) return [{
    __typename: 'PageBodyHeader',
    id: 'header'
  }].concat(_toConsumableArray(splice(ctaSliceIndex, 0, contactInfoSlice, list)), [{
    __typename: 'PageBodyFooter',
    id: 'footer'
  }]);
  return [{
    __typename: 'PageBodyHeader',
    id: 'header'
  }].concat(_toConsumableArray(list), [contactInfoSlice, {
    __typename: 'PageBodyFooter',
    id: 'footer'
  }]);
};

export const ContactPage = _ref => {
  let {
    data,
    location
  } = _ref,
      props = _objectWithoutProperties(_ref, ["data", "location"]);

  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    sliesMiddleware: slicesMiddleware
  }, props));
};
export default ContactPage;
export const query = "1604691817";